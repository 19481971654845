<template>
	<div>
		<b-button class="btn-icon" variant="flat-dark" size="small"
			@click="togglePlayer"
		>
      <feather-icon v-if="!isPlaying" icon="PlayIcon" />
      <feather-icon v-else icon="PauseIcon" />
    </b-button>
		<audio class="w-100 hidden" ref="player" :src="src" controls="false" preload="metadata">
			<p>Alas, your browser doesn't support html5 audio.</p>
		</audio>
	</div>
</template>
<script>
	export default {
		props: {
			src: { type: String }
		},
		data() {
			return {
				isPlaying: false
			}
		},
		computed: {
			player() {
				return this.$refs.player;
			}
		},
		methods: {
			togglePlayer() {
				this.isPlaying = !this.isPlaying;
				if(!this.isPlaying) {
					this.player.load();
				} else {
					this.player.play();
				}
			}
		},
		mounted() {
			this.player.addEventListener('ended', this.togglePlayer);
		}
	}
</script>
